import React from 'react';
import {graphql} from 'gatsby';
import Blog from '../components/Blog';
import Layout from '../components/Layout';

const CategoryTemplate = ({data, location, pageContext}) => {
    const {edges} = data.allMarkdownRemark
    return (<Layout title="CyberVision - Blog" location={location} pageTitle={`CATEGORY: ${edges[0].node.frontmatter.category}`} darkBreadcrumbs>
        <Blog edges={edges} location={location} pageContext={pageContext}/>
    </Layout>)
}

export const query = graphql`
    query Postbycategory ($categorySlug: String) {
        allMarkdownRemark(
            filter: {frontmatter: {template: {eq: "post"}, draft: {ne: true}}, fields: {categorySlug: {eq: $categorySlug}}}
            sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    fields {
                        slug
                        tagSlugs
                        categorySlug
                    }
                    frontmatter {
                        title
                        date
                        category
                        tags
                        description
                        socialImage
                    }
                }
            }
        }
    }
`;

export default CategoryTemplate
